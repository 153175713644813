<template>
  <div class="header">
    <div class="flex justify-between items-center header-content">
      <div class="left">
        <!-- <div class="logo">
          <img src="@/assets/imgs/KDPAY- LOGO 2.png" alt="" />
        </div> -->
        <div>SMART PAY</div>
      </div>
      <!-- PC端菜单 -->
      <div class="flex justify-start items-center right desktop-menu">
        <!-- <el-button type="info" class="customBtn loginBtn" @click="toHome">{{
          $t("首页")
        }}</el-button>
        <el-button type="info" class="customBtn loginBtn" @click="toActive">{{
          $t("激活指南")
        }}</el-button> -->
        <el-button type="info" class="customBtn loginBtn" @click="toLogin">{{
          $t("登录")
        }}</el-button>
        <el-button type="primary" class="customBtn" @click="toLogin">{{
          $t("注册")
        }}</el-button>
        <el-button type="primary" class="customBtn" @click="toLogin">{{
          $t("申请卡片")
        }}</el-button>
        <el-dropdown
          trigger="click"
          @command="handleCommand"
          placement="bottom"
        >
          <div class="langBox">
            <img src="@/assets/imgs/language.png" alt="" class="language" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(lang, index) in langs"
              :key="index"
              :command="lang"
              :class="curLanguage == lang.value ? 'languageActive' : ''"
              >{{ lang.text }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 移动端菜单按钮 -->
      <!-- <div class="mobile-menu-button" @click="drawerVisible = true">
        <div class="hamburger-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> -->
    </div>

    <!-- 移动端抽屉菜单 -->
    <!-- <div
      v-if="drawerVisible"
      class="mobile-drawer-overlay"
      @click="drawerVisible = false"
    ></div>
    <div class="mobile-drawer" :class="{ 'drawer-open': drawerVisible }">
      <div class="drawer-content">
        <div class="drawer-header">
          <div class="close-button" @click="drawerVisible = false">
            <div class="close-icon"></div>
          </div>
        </div>
        <div class="drawer-body">
          <div class="menu-buttons">
            <el-button type="info" class="drawer-btn" @click="toHome">
              {{ $t("首页") }}
            </el-button>
            <el-button type="info" class="drawer-btn" @click="toActive">
              {{ $t("激活指南") }}
            </el-button>
            <el-button type="info" class="drawer-btn" @click="toLogin">
              {{ $t("登录") }}
            </el-button>
            <el-button type="primary" class="drawer-btn" @click="toLogin">
              {{ $t("注册") }}
            </el-button>
            <el-button type="primary" class="drawer-btn" @click="toLogin">
              {{ $t("申请卡片") }}
            </el-button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "header-page",
  data() {
    return {
      // drawerVisible: false,
      langs: [
        { text: "English", value: "en" },
        { text: "中文", value: "zh" },
        { text: "日本语", value: "jp" },
      ],
      curLanguage: localStorage.getItem("accept_language"),
      currentLan: "",
    };
  },
  methods: {
    handleCommand(command) {
      localStorage.setItem("accept_language", command.value);
      this.$i18n.locale = command.value;
      this.currentLan = this.langs.find(
        (item) => item.value === command.value
      )?.text;
      window.location.reload();
    },
    toLogin() {
      window.open("https://card.insmartpay.com/login");
    },
    toHome() {
      // 根据当前设备判断浏览器宽度
      if (window.innerWidth < 768) {
        window.location.href = "https://h5.insmartpay.com/h5";
      } else {
        window.location.href = "https://insmartpay.com";
      }
    },
    toActive() {
      window.open("https://insmartpay.com/opencard");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 10px;
}
.header {
  width: 100%;
  background-color: #000000;

  .header-content {
    max-width: 1440px;
    height: 82px;
    padding: 18px 60px;
    margin: 0 auto;
    color: #fff;
  }

  .left {
    display: flex;
    align-items: center;
    .logo {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    font-family: "MiSans-Bold";
    font-size: 26px;
    line-height: 35.67px;
    text-align: left;
  }

  .right {
    .loginBtn {
      color: #9b6d09;
    }
    .language {
      margin-left: 20px;
      cursor: pointer;
    }
    .active {
      background-color: transparent;
      background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "MiSans-Demibold";
      font-size: 14px;
      font-weight: 600;
      line-height: 18.56px;
      text-align: left;
    }
  }

  .mobile-menu-button {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }

  .header {
    .header-content {
      padding: 18px 20px;
    }

    .desktop-menu {
      display: none;
    }

    .left {
      font-size: 20px;

      .logo {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
