const jp = {
  登录: "ログイン",
  注册: "登録",
  申请卡片: "カード申請",
  USDT区块链: "USDTブロックチェーン",
  数字货币与传统金融的跨界交融:
    "デジタル通貨と伝統的な金融のクロスボーダー融合",
  "创新性金融整合应用体，财富跨界安全自由":
    "革新的な金融統合アプリケーション体で、富のクロスボーダーが安全で自由です",
  核心功能: "コア機能",
  全球提现: "グローバルキャッシュアウト",
  "全球275个地区与国家的实体卡自由提现服务。从容面对，安全体验":
    "世界275の地域と国の実体カードによる自由なキャッシュアウトサービス。落ち着いて対処し、安全な体験を提供します",
  全球消费: "グローバル消費",
  "撒欢自在，从现在开始，做你想做的事":
    "心ゆくまで楽しく、今から始めて、あなたがしたいことをしましょう",
  账户管理: "アカウント管理",
  "开卡、充值、冻结、注销操作尽在掌控，体验拉满，事事安心":
    "カード開設、チャージ、凍結、解約の操作はすべてコントロールでき、充実した体験を提供し、すべてのことに安心できます",
  虚实互动: "バーチャルとリアルのインタラクション",
  "虚拟VCC快速开卡，时时掌控，快速消费数字USDT极速转换，刻刻转换，跨界便捷":
    "バーチャルVCCでのカード開設が迅速で、常時コントロールでき、デジタルUSDTの高速変換で、迅速に消費でき、クロスボーダーが便利です",
  支持: "サポート",
  "真实感受区块链货币与现实货币的无限可能!是USDT也是您手握的现金":
    "ブロックチェーン通貨と現実の通貨の無限の可能性をリアルに感じましょう！USDTであり、あなたが手に持つ現金でもあります",
  随时随地的全球消费: "いつでもどこでものグローバル消費",
  "从现在开始，享受非凡的优质消费体验":
    "今から始めて、非凡で優れた消費体験をお楽しみください",
  "支持全球范围的无限支持全球商户的无限制消费，任何时间任何地点制消费，走到哪里都不怕":
    "グローバル範囲での無限のサポートで、世界中のマーチャントの制限なしの消費をサポートし、いつでもどこでも消費でき、どこに行っても心配ありません",
  "数字货币快速转换，真金白银时时到账":
    "デジタル通貨の迅速な変換で、本当のお金が常時入金されます",
  "真金白银时时到账是陌生的领域，也是您熟悉的家园，一卡通全球":
    "本当のお金が常時入金されるのは見慣れない領域でありながら、あなたにとって馴染みのある家でもあり、一つのカードで世界中を通じます",
  无界卡: "ボーダレスカード",
  "24小时贴身顾问服务，专属秘书服务":
    "24時間体制のパーソナルコンサルタントサービスと専属秘書サービス",
  "专业出行行程规划、机票、餐饮、旅行、全方位的专属服务":
    "専門的な出張行程計画、航空券、飲食、旅行、全方位的な専属サービス",
  "高端专属私密社交互动，人脉拓展的便捷通道":
    "ハイエンド専属のプライベートソーシャルインタラクション、人脈拡大の便利なチャネル",
  "私人预定服务，高端会所、食所、专属预约":
    "プライベート予約サービス、ハイエンドクラブ、飲食店、専属予約",
  "游艇、私人飞机、豪车预定服务，我们搞定所有，您尽情享受":
    "ヨット、プライベートジェット、高級車の予約サービスは、私たちがすべて手配しますので、存分にお楽しみください",
  "特别后端支撑服务，全面用卡体验":
    "特別なバックエンドサポートサービスで、カード使用の全面的な体験を提供します",
  "7x24小时在线贵宾服务": "7x24時間オンラインVIPサービス",
  "全年无休，提供7X24小时的在线服务，周年纪念日、办公聚餐、商务旅行及私人旅行定制等全方位的卡片福利礼遇服务":
    "年中無休で、7X24時間のオンラインサービスを提供し、記念日、オフィス会食、ビジネス旅行及びプライベート旅行のカスタマイズなど、全方位のカード特典と礼遇サービスを提供します",
  线上线下的高水准使用: "オンラインとオフラインの高水準な使用",
  "线上线下的全覆盖使用体验，ApplePay、GooglePAY、PayPay等全线上绑卡消费，同步支持线下无卡消费场景":
    "オンラインとオフラインの全カバー使用体験で、ApplePay、GooglePAY、PayPayなどの全オンラインカード紐付け消費をサポートし、同時にオフラインのカードレス消費シーンもサポートします",
  Visa及Master顶级支持: "Visa及びMasterのトップレベルサポート",
  "全球两大顶级卡组支持，可在世界大部分地区使用，体验无与伦比的地位":
    "世界の二大トップレベルカードグループのサポートで、世界の大部分の地域で使用でき、比類なき地位を体験できます",
  本地及海外顶级酒店预定: "国内及び海外のトップホテル予約",
  "无论在本地还是海外，超过千家住宿酒店中，您可享受优先预定、客房升级、免费早餐、提前入住、延迟退房等各种优惠服务":
    "国内でも海外でも、千軒以上の宿泊ホテルで、優先予約、客室アップグレード、無料朝食、早期チェックイン、遅延チェックアウトなどの様々な特典サービスをお楽しみいただけます",
  免费机场出行休息厅服务: "無料空港出発ラウンジサービス",
  "免费出行特权，可使用多个国家和地区超过高达600城市的1500多个机场及出行场所":
    "無料の出発特権で、多くの国と地域の600以上の都市の1500以上の空港及び出発場所を利用できます",
  豪华酒店及高端餐饮服务: "豪華ホテル及びハイエンド飲食サービス",
  "提过优质的VIP待遇，可享受优先预定高端特殊餐饮的预定服务":
    "質の高いVIP待遇を提供し、ハイエンド特殊飲食の優先予約サービスをお楽しみいただけます",
  专属vip特殊通道服务: "専属VIP特殊チャネルサービス",
  "通过kdcard，可支持预定特殊场所的服务，无需担心，KdCard可以帮助您完成高端会员制会所及非开放式招待场所的特殊预约服务":
    "kdcardを通じて、特殊な場所の予約サービスをサポートできます。心配する必要はありません。KdCardはハイエンド会員制クラブ及び非オープン式接待場所の特殊予約サービスを完了するのをお手伝いできます",
  专享非公众开放的独家福利: "一般公開されていない独占的な特典を専有できます",
  "通过卡片，可专享独家福利待遇，我们将提供私密专属高端场所预定、部分限量发行商品的优先购买权、预售及特殊活动邀请等独家福利":
    "カードを通じて、独占的な特典待遇を専有できます。私たちはプライベート専属のハイエンド場所の予約、一部の限定発行商品の優先購入権、プレセール及び特殊イベントへの招待などの独占的な特典を提供します",
  首年年费减免: "初年度年会費減免",
  "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100%享受首年年费减免服务":
    "カード申請初年度年会費減免サービスで、超優遇政策を通じて、今カードを申請すると、初年度年会費減免サービスを100％享受できます",
  专属服务: "専属サービス",
  "卡片专属福利服务，出行、商务、旅游、住宿，精彩横生包罗万象":
    "カード専属の特典サービスで、出張、ビジネス、旅行、宿泊など、素晴らしいものが豊富に揃っています",
  年费: "年会費",
  "金卡：350 美金": "ゴールドカード：350 ドル",
  "白金卡：760美金": "プラチナカード：760 ドル",
  "黑金卡：1500美金": "ブラックカード：1500 ドル",
  开年卡首年达标免年费: "カード開設初年度の達成基準による年会費免除",
  附加卡: "追加カード",
  家庭卡免费x2: "ファミリーカード無料×2",
  公司卡免费x2: "会社カード無料×2",
  转U费用: "Uへの転送費用",
  "1+1美金/笔": "1 + 1 ドル/取引",
  充值费用: "チャージ費用",
  "2%/笔": "2%/取引",
  支持品牌: "サポートブランド",
  发卡时限: "カード発行期限",
  VCC申请后2小时内: "VCC申請後2時間以内",
  年龄限制: "年齢制限",
  "20岁以上群体": "20歳以上のグループ",
  特定增值: "特定の付加価値",
  服务: "サービス",
  根据卡类享受不同的特定福利:
    "カードの種類によって異なる特定の特典を享受できます",
  申请步骤介绍: "申請ステップの紹介",
  "您仅需享受最快10分钟申请 立即拥有输你的自己的SmartCard":
    "あなたは最速10分で申請でき、すぐにあなただけのSmartCardを持つことができます",
  "申请前务必核对申请条件及基础信息!":
    "申請前に必ず申請条件と基礎情報を確認してください！",
  "在线会员申请，注册1分钟": "オンライン会員申請で、登録は1分間",
  "完成会员申请后开卡，开卡填写资料最长5分钟":
    "会員申請を完了した後、カード開設のための資料記入は最長5分間",
  分钟: "分間",
  "将USDT转入钱包，增加自己的数字资产":
    "USDTをウォレットに転送し、自分のデジタル資産を増やします",
  "（请根据需求，量力而行）":
    "（ニーズに応じて、自分の能力に合わせて行ってください）",
  "申请开卡，选择您希望的品牌卡片（visa":
    "カード開設を申請し、あなたが望むブランドのカード（visa",
  "或master），将USDT转入卡内转变为消":
    "またはmaster）を選択し、USDTをカードに転送して消費額に変換します",
  "费额度（此操作可重复进行，可逆向操":
    "費用額（この操作は繰り返し行うことができ、逆方向の操作も可能です）",
  "作，U转卡2%-3%费用，卡转回U免费）":
    "操作で、Uからカードへの転送には2％ - 3％の費用がかかり、カードからUへの転送は無料です）",
  立即开卡: "すぐにカードを開設する",
  语言: "言語",
  支付产品: "支払い製品",
  线下支付: "オフライン支払い",
  线上支付: "オンライン支払い",
  关于我们: "私たちについて",
  USDT支持: "USDTサポート",
  收费标准: "料金標準",
  网上支付: "オンライン支払い",
  联系我们: "お問い合わせ",
  常见问题: "よくある質問",
  网站使用条款: "ウェブサイト利用規約",
  Cookie声明: "Cookie声明",
  立即下载: "今すぐダウンロード",
  跨境线上消费模式: "クロスボーダーオンライン消費モード",
  "世界有多大，心就有多大，支持绑定多电子支付场景，GooglePlay、AppStore、Steam、 Amazon. 全宇宙都是你的消费乐园!":
    "世界がどれだけ大きいかによって、心もそれだけ大きくなります。GooglePlay、AppStore、Steam、Amazonなどの多くの電子決済シーンのバインドをサポートし、全宇宙があなたの消費の楽園です！",
  费率及费用介绍: "料率及び費用の紹介",
  用户协议: "ユーザー同意書",
  隐私协议: "プライバシー規約",
  账户删除声明: "アカウント削除声明",
  激活指南: "アクティベーションガイド",
  首页: "ホーム",
};
export default jp;
