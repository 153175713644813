<template>
  <WrapBox>
    <div class="onePage" ref="qudao">
      <!-- <Header /> -->
      <div class="main" v-show="qudaoShow">
        <div class="title animate__animated animate__fadeInUp">
          {{ $t("USDT区块链") }} X
          <span
            v-for="(char, index) in textArray"
            :key="index"
            class="hot"
            ref="chars"
            >{{ char }}</span
          >
          <!-- <span class="hot" id="hot">Master &Visa</span> -->
        </div>
        <div class="flex justify-between items-start middle">
          <div class="left" style="z-index: 100">
            <p id="textElement">
              <span class="reveal" style="display: none" ref="textElement">{{
                $t("数字货币与传统金融的跨界交融")
              }}</span>
            </p>
            <p id="textElement1">
              <span class="reveal" style="display: none" ref="textElement1">{{
                $t("创新性金融整合应用体，财富跨界安全自由")
              }}</span>
            </p>
            <div class="flex" style="margin-top: 60px; z-index: 200">
              <a
                href="javascript:void(0)"
                style="margin-right: 30px"
                @click="showModal = true"
              >
                <img
                  src="@/assets/imgs/appstore.png"
                  width="285"
                  height="80"
                  alt=""
                />
              </a>
              <img
                src="@/assets/imgs/android.png"
                width="285"
                height="80"
                alt=""
                @click="showModal = true"
                style="cursor: pointer"
              />
            </div>
            <div class="channel-code-text" @click="showModal = true">
              Get your channel code for card discount. Tap to get
            </div>
          </div>
          <div
            class="flex flex-col justify-start items-start right"
            :style="currentLanguage == 'zh' ? 'height: 100%' : 'height: 80%'"
          >
            <div class="imgBox1 animate__animated animate__fadeInTopRight">
              <img src="@/assets/imgs/card1.png" alt="" class="img1" />
            </div>
            <div class="imgBox2 animate__animated animate__fadeInTopRight">
              <img src="@/assets/imgs/card2.png" alt="" class="img1" />
            </div>
            <div class="imgBox3 animate__animated animate__fadeInTopRight">
              <img src="@/assets/imgs/card3.png" alt="" class="img1" />
            </div>
          </div>
        </div>
      </div>
      <img
        src="@/assets/imgs/left.png"
        alt=""
        class="animate__animated animate__rotateIn leftImg"
      />
      <img
        src="@/assets/imgs/right.png"
        alt=""
        class="animate__animated animate__rotateIn rightImg"
      />
    </div>
    <!-- 渠道码弹框 -->
    <!-- 更新 -->
    <Transition name="modal">
      <div v-if="showModal" class="modal-overlay" @click="showModal = false">
        <div class="modal-content channel-modal" @click.stop>
          <div class="modal-header">
            <div class="header-title">
              <h2 style="font-size: 28px; font-weight: 600">Channel Code</h2>
            </div>
            <button class="close-button" @click="showModal = false">
              <img
                src="@/assets/imgsnew/Vector.png"
                alt="close"
                class="close-icon"
              />
            </button>
          </div>
          <div class="modal-body">
            <!-- 弹框内容区域 -->
            <div class="modal-content-body">
              <!-- 获取渠道码 -->
              <div class="modal-content-body-title">
                <div class="modal-content-body-code" @click="copyChannelCode">
                  <span>{{ currentChannelCode }}</span>
                  <span
                    v-if="currentChannelCode !== 'Click to Get Channel Code'"
                    >&nbsp;</span
                  >
                  <img
                    v-if="currentChannelCode !== 'Click to Get Channel Code'"
                    src="@/assets/imgsnew/copy.png"
                    alt="copy"
                    class="copy-icon"
                  />
                </div>
              </div>
              <!-- 渠道码注释 -->
              <div class="modal-content-body-code-text">
                <span
                  >Fill in the channel code on the registration page to register
                  and get an exclusive card opening discount</span
                >
              </div>
              <!-- 渠道码说明 -->
              <div class="modal-content-body-code-text2">
                <span>Channel Code Instructions:</span>
                <div class="modal-content-body-code-text2-img">
                  <img src="@/assets/imgsnew/qietu3.png" alt="code" />
                </div>
              </div>
              <!-- 操作步骤 -->
              <div class="modal-content-body-code-text2-img-text">
                <p>
                  <span>STEP1：</span>Open the app, tap the "Create Account"
                  button, and go to the registration page
                </p>
                <p>
                  <span>STEP2：</span>Enter your exclusive channel code on the
                  registration page and complete the registration to enjoy the
                  card opening discount
                </p>
              </div>
              <!-- 下载按钮 -->
              <div>
                <div class="modal-content-body-download">
                  <img
                    src="@/assets/imgs/appstore.png"
                    alt="Download on App Store"
                    class="store-button"
                    @click="downloadIOS()"
                  />
                  <img
                    src="@/assets/imgs/android.png"
                    alt="Download on Android"
                    class="store-button"
                    @click="download()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </WrapBox>
</template>

<script>
import WrapBox from "./wrap/wrapBox.vue";
// import Header from "./one/header.vue";
import gsap from "gsap";
// import { SplitText } from "split-text";
export default {
  name: "OnePage",
  components: { WrapBox },
  data() {
    return {
      qudaoShow: true,
      text: "Master &Visa",
      textArray: [],
      currentLanguage: "zh",
      showModal: false,
      channelCodes: [
        "wphsqk",
        "kttdmw",
        "dnlvgm",
        "ucjklj",
        "kdsqiw",
        "nmtkcc",
        "eqijda",
        "ryvvbx",
        "hxlerj",
        "krtgvn",
      ],
      currentChannelCode: "Click to Get Channel Code",
    };
  },
  mounted() {
    this.textArray = this.text.split(""); // 将文本分割为字符数组
    this.animateText(); // 调用动画函数
    const text = this.$refs.textElement.innerText;
    const letters = text.split("");
    const container = document.getElementById("textElement");
    letters.forEach((letter, index) => {
      const span = document.createElement("span");
      span.classList.add("reveal");
      span.textContent = letter;

      setTimeout(() => {
        container.appendChild(span);
        span.classList.add(
          "animate__animated",
          "animate__fadeInUp",
          "animate__faster"
        );
        if (index == letters.length - 1) {
          const text1 = this.$refs.textElement1.innerText;
          const letters1 = text1.split("");
          const container1 = document.getElementById("textElement1");
          letters1.forEach((letter, index) => {
            const span = document.createElement("span");
            span.classList.add("reveal");
            span.textContent = letter;

            setTimeout(() => {
              container1.appendChild(span);
              span.classList.add(
                "animate__animated",
                "animate__fadeInUp",
                "animate__faster"
              );
            }, 100 * index);
          });
        }
      }, 100 * index);
    });
    this.currentLanguage = localStorage.getItem("accept_language");
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 10, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (c <= a) {
          callback && callback();
        }
        if (b > c) {
          this.qudaoShow = false;
        } else {
          this.qudaoShow = true;
        }
      }
    },
    animateText() {
      const chars = this.$refs.chars;
      gsap.from(chars, {
        duration: 0.5,
        opacity: 0,
        y: 10,
        stagger: 0.1, // 每个字符之间的延迟
        ease: "power1.out",
      });
    },
    download() {
      window.location.href =
        "https://d1qv1lnrgfy0u7.cloudfront.net/apk/smartpay_official.apk";
      this.showModal = false;
    },
    downloadIOS() {
      window.location.href =
        "https://apps.apple.com/hk/app/insmartpay/id6738124852";
      this.showModal = false;
    },
    showChannelCodeModal(type) {
      this.downloadType = type;
      this.showModal = true;
    },
    getRandomChannelCode() {
      const randomIndex = Math.floor(Math.random() * this.channelCodes.length);
      this.currentChannelCode = this.channelCodes[randomIndex];
    },
    async copyChannelCode() {
      if (this.currentChannelCode == "Click to Get Channel Code") {
        this.getRandomChannelCode();
        return;
      }

      try {
        const textArea = document.createElement("textarea");
        textArea.value = this.currentChannelCode;
        textArea.style.position = "fixed";
        textArea.style.left = "-9999px";
        textArea.style.top = "0";
        document.body.appendChild(textArea);

        textArea.focus();
        textArea.select();

        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, textArea.value.length);

        const successful = document.execCommand("copy");
        document.body.removeChild(textArea);

        if (successful) {
          alert("Channel code copied successfully!");
        } else {
          alert("no");
        }
      } catch (err) {
        console.error("复制失败:", err);
        alert("no");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.channel-code-text {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
  //下划线
  text-decoration: underline;
  //小手
  cursor: pointer;
}
.onePage {
  height: 100%;
  background: url("@/assets/imgs/bg1.png") no-repeat;
  font-size: 20px;
  color: #fff;
  position: relative;
  overflow: hidden;
  .leftImg {
    position: absolute;
    width: 352px;
    height: 467px;
    bottom: -233px;
    left: -30px;
    opacity: 0.6px;
    animation: rotate 20s linear infinite;
    z-index: 0;
  }
  .rightImg {
    position: absolute;
    width: 352px;
    height: 467px;
    bottom: 10%;
    right: -170px;
    opacity: 0.6px;
    animation: rotate 20s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .main {
    height: 100%;
    padding: 136px 120px 20px;
    .title {
      animation: fadeInUp 1s linear forwards;
      font-size: 40px;
    }
    .hot {
      background-image: linear-gradient(to right, #846c37, #5f4b1f 50%);
      // // background: linear-gradient(
      // //   135deg,
      // //   #846c37,
      // //   #5f4b1f,
      // //   // rgb(255, 0, 225),
      // //   // rgb(34, 0, 255),
      // //   // red
      // // );
      // // green;
      color: transparent;
      -webkit-background-clip: text;
      background-size: 200% 100%;
      animation: flowlight 5s forwards;
      // animation: fadeInUp 2s linear forwards;
      // animation: colorChange 3s forwards;
    }
    @keyframes flowlight {
      0% {
        filter: hue-rotate(0deg);
      }
      100% {
        color: #fff;
      }
    }
    .middle {
      margin-top: 8px;
      height: 100%;
      p {
        opacity: 0; /* 初始透明度 */
        transform: translateX(-20px); /* 初始位置 */
        animation: slideIn 1s forwards; /* 动画时长和效果 */
        font-size: 24px;
        line-height: 31.82px;
        text-align: left;
      }
      .left {
        margin-top: 15px;
        height: 100%;
        width: 50%;
        .download {
          position: absolute;
          z-index: 9;
          background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
          margin-top: 60px;
          min-width: 200px;
          padding: 0 50px;
          height: 80px;
          border-radius: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          line-height: 24px;
          text-align: left;
          cursor: pointer;
          &:hover {
            background: linear-gradient(180deg, #e2a63e 0%, #966728 100%);
            border: none;
            color: #fff;
          }
        }
      }
      .right {
        position: relative;
        flex: 1;
        .imgBox1,
        .imgBox2,
        .imgBox3 {
          img {
            width: 695.39px;
            height: 263.69px;
            border-radius: 32.38px 0px 0px 0px;
            border: 3.24px 0px 0px 0px;
          }
        }
        .imgBox1 {
          position: absolute;
          bottom: 50%;
          right: 10px;
          animation: fadeInTopRight 3s alternate;
          z-index: 111;
        }
        .imgBox2 {
          position: absolute;
          bottom: 30%;
          right: 20px;
          z-index: 110;
          animation: fadeInTopRight 2s alternate;
        }
        .imgBox3 {
          position: absolute;
          bottom: 15%;
          right: 30px;
          z-index: 100;
          animation: fadeInTopRight 1s alternate;
        }
      }
    }
  }
  @keyframes slideIn {
    to {
      opacity: 1; /* 最终透明度 */
      transform: translateX(0); /* 到达原位 */
    }
  }
  @keyframes colorChange {
    0% {
      color: #846c37;
    }
    50% {
      color: #5f4b1f;
    }
    80% {
      color: #846c37;
    }
    100% {
      color: #fff;
    }
  }
}

/* iOS确认弹框样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  z-index: 40;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  .header-title {
    text-align: center;
    width: 100%;

    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #333;
      margin: 0;
    }
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.close-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

/* Modal Transition Animation */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-to,
.modal-leave-from {
  opacity: 1;
}

/* 渠道码弹框样式 */
.channel-modal {
  background-color: white;
  width: 30vw;
  height: 42vw;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;

  .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    .header-title {
      text-align: center;
      width: 100%;

      h2 {
        font-size: 28px;
        font-weight: 600;
        color: #333;
        margin: 0;
      }
    }

    .close-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal-content-body-title {
  background: #eef4fe;
  width: 100%;
  border-radius: 20px;
  padding: 10px 10px;
}

.modal-content-body-code {
  width: 100%;
  height: 40px;
  background: #000000;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  gap: 8px;

  &:hover {
    background: #333333;
  }

  span {
    color: #fff;
    font-family: MiSans;
    font-weight: 500;
    font-size: 16px;
    line-height: 23.87px;
    letter-spacing: 0%;
    user-select: all;
  }
}

.modal-content-body-code-text span {
  display: block;
  font-family: MiSans;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.91px;
  letter-spacing: 0%;
  padding: 10px 0;
}

.modal-content-body-code-text2 span {
  display: block;
  font-family: MiSans;
  font-weight: 600;
  font-size: 16px;
  line-height: 15.91px;
  letter-spacing: 0%;
  padding-bottom: 10px;
}

.modal-content-body-code-text2-img-text {
  span {
    color: red;
    font-family: MiSans;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0%;
  }
  p {
    margin: 12px 0;
    font-size: 12px;
    line-height: 1.6;
    opacity: 1;
    transform: none;
    animation: none;
  }
}

.modal-content-body-download {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;

  img {
    width: 160px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 768px) {
  .channel-modal {
    width: 90vw;
    height: auto;
  }

  .modal-header {
    .header-title {
      h2 {
        font-size: 22px;
      }
    }
  }

  .modal-content-body-download img {
    width: 140px;
    height: 32px;
  }

  .modal-content-body-code {
    height: 48px;
  }

  .modal-content-body-code span {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .channel-modal {
    width: 95vw;
  }

  .modal-content-body-code {
    height: 44px;
  }

  .modal-content-body-code span {
    font-size: 16px;
  }

  .modal-content-body-code-text span,
  .modal-content-body-code-text2-img-text p {
    font-size: 14px;
  }
}
</style>
