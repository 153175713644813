const en = {
  登录: "Login",
  注册: "Register",
  申请卡片: "Apply for Card",
  USDT区块链: "USDT Blockchain",
  数字货币与传统金融的跨界交融:
    "Cross-border Integration of Digital Currency and Traditional Finance",
  "创新性金融整合应用体，财富跨界安全自由":
    "Innovative Financial Integration Application Entity, Safe and Free Cross-border Wealth",
  "Innovative Financial Integration Application, Safe and Free Cross-border Wealth":
    "Innovative Financial Integration Application, Safe and Free Cross-border Wealth",
  核心功能: "Core Functions",
  全球提现: "Global Withdrawal",
  "全球275个地区与国家的实体卡自由提现服务。从容面对，安全体验":
    "Free withdrawal service for physical cards in 275 regions and countries worldwide. Face it calmly and enjoy a safe experience",
  全球消费: "Global Consumption",
  "撒欢自在，从现在开始，做你想做的事":
    "Have fun and be free, from now on, do what you want to do",
  账户管理: "Account Management",
  "开卡、充值、冻结、注销操作尽在掌控，体验拉满，事事安心":
    "Operations such as card opening, recharge, freezing, and cancellation are all under control, providing a full experience and peace of mind for everything",
  虚实互动: "Virtual and Real Interaction",
  "虚拟VCC快速开卡，时时掌控，快速消费数字USDT极速转换，刻刻转换，跨界便捷":
    "Quick card opening with virtual VCC, real-time control, rapid conversion of digital USDT, instant conversion, and convenient cross-border transactions",
  支持: "Support",
  "真实感受区块链货币与现实货币的无限可能!是USDT也是您手握的现金":
    "Truly experience the infinite possibilities of blockchain currency and real currency! It is both USDT and the cash in your hand",
  随时随地的全球消费: "Global Consumption Anytime, Anywhere",
  "从现在开始，享受非凡的优质消费体验":
    "From now on, enjoy an extraordinary and high-quality consumption experience",
  "支持全球范围的无限支持全球商户的无限制消费，任何时间任何地点制消费，走到哪里都不怕":
    "Support unlimited consumption for global merchants worldwide, consume at any time and any place, and don't be afraid wherever you go",
  "数字货币快速转换，真金白银时时到账":
    "Quick conversion of digital currency, real money arrives in your account at all times",
  "真金白银时时到账是陌生的领域，也是您熟悉的家园，一卡通全球":
    "Real money arrives in your account at all times. It is both a unfamiliar field and a familiar home, one card for the whole world",
  无界卡: "Boundless Card",
  "24小时贴身顾问服务，专属秘书服务":
    "24-hour personal consultant service, exclusive secretary service",
  "专业出行行程规划、机票、餐饮、旅行、全方位的专属服务":
    "Professional travel itinerary planning, air tickets, catering, travel, and all-round exclusive services",
  "高端专属私密社交互动，人脉拓展的便捷通道":
    "High-end exclusive private social interaction, a convenient channel for expanding your network",
  "私人预定服务，高端会所、食所、专属预约":
    "Private reservation service, high-end clubs, restaurants, and exclusive appointments",
  "游艇、私人飞机、豪车预定服务，我们搞定所有，您仅需享受":
    "Yacht, private jet, luxury car reservation service, we take care of everything, and you enjoy it to the fullest",
  "特别后端支撑服务，全面用卡体验":
    "Special backend support service, comprehensive card usage experience",
  "7x24小时在线贵宾服务": "7x24-hour online VIP service",
  "全年无休，提供7X24小时的在线服务，周年纪念日、办公聚餐、商务旅行及私人旅行定制等全方位的卡片福利礼遇服务":
    "Open all year round, providing 7X24-hour online service, and comprehensive card benefits and courtesy services such as anniversary celebrations, office dinners, business trips, and private travel customization",
  线上线下的高水准使用: "High-level Online and Offline Usage",
  "线上线下的全覆盖使用体验，ApplePay、GooglePAY、PayPay等全线上绑卡消费，同步支持线下无卡消费场景":
    "Full coverage usage experience online and offline, with full online card binding consumption such as ApplePay, GooglePAY, PayPay, and simultaneous support for offline cardless consumption scenarios",
  Visa及Master顶级支持: "Top-level Support from Visa and Master",
  "全球两大顶级卡组支持，可在世界大部分地区使用，体验无与伦比的地位":
    "Supported by the world's two top card groups, available in most parts of the world, and experience an unparalleled status",
  本地及海外顶级酒店预定: "Local and Overseas Top Hotel Reservation",
  "无论在本地还是海外，超过千家住宿酒店中，您可享受优先预定、客房升级、免费早餐、提前入住、延迟退房等各种优惠服务":
    "Whether at home or abroad, in more than a thousand accommodation hotels, you can enjoy various preferential services such as priority reservation, room upgrade, free breakfast, early check-in, and late check-out",
  免费机场出行休息厅服务: "Free Airport Lounge Service",
  "免费出行特权，可使用多个国家和地区超过高达600城市的1500多个机场及出行场所":
    "Free travel privileges, accessible to more than 1500 airports and travel venues in up to 600 cities in multiple countries and regions",
  豪华酒店及高端餐饮服务: "Luxury Hotel and High-end Dining Service",
  "提过优质的VIP待遇，可享受优先预定高端特殊餐饮的预定服务":
    "Providing high-quality VIP treatment, you can enjoy priority reservation services for high-end special dining",
  专属vip特殊通道服务: "Exclusive VIP Special Channel Service",
  "通过kdcard，可支持预定特殊场所的服务，无需担心，KdCard可以帮助您完成高端会员制会所及非开放式招待场所的特殊预约服务":
    "Through kdcard, you can support the reservation service for special venues. Don't worry, KdCard can help you complete the special reservation service for high-end membership clubs and non-open hospitality venues",
  专享非公众开放的独家福利: "Exclusive Benefits Not Open to the Public",
  "通过卡片，可专享独家福利待遇，我们将提供私密专属高端场所预定、部分限量发行商品的优先购买权、预售及特殊活动邀请等独家福利":
    "Through the card, you can enjoy exclusive benefits. We will provide exclusive reservations for private and exclusive high-end venues, priority purchase rights for some limited-edition products, pre-sales, and invitations to special events and other exclusive benefits",
  首年年费减免: "First-year Annual Fee Waiver",
  "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100":
    "First-year annual fee waiver service for card application. Through super preferential policies, if you apply for the card now, you can enjoy 100%",
  专属服务: "Exclusive Service",
  "卡片专属福利服务，出行、商务、旅游、住宿，精彩横生包罗万象":
    "Card-exclusive welfare services, including travel, business, tourism, and accommodation, full of wonderful and diverse offerings",
  年费: "Annual Fee",
  "金卡：350 美金": "Gold Card: 350 US dollars",
  "白金卡：760美金": "Platinum Card: 760 US dollars",
  "黑金卡：1500美金": "Black Gold Card: 1500 US dollars",
  开年卡首年达标免年费:
    "Annual Fee Waiver for Meeting the Standard in the First Year of Opening the Card",
  附加卡: "Additional Card",
  家庭卡免费x2: "Family Card Free x2",
  公司卡免费x2: "Company Card Free x2",
  转U费用: "Transfer U Fee",
  "1+1美金/笔": "1 + 1 US dollars/transaction",
  充值费用: "Recharge Fee",
  "2%/笔": "2%/transaction",
  支持品牌: "Supported Brands",
  发卡时限: "Card Issuance Time Limit",
  VCC申请后2小时内: "Within 2 hours after VCC application",
  年龄限制: "Age Limit",
  "20岁以上群体": "People over 20 years old",
  特定增值: "Specific Value-added",
  服务: "Service",
  根据卡类享受不同的特定福利:
    "Enjoy different specific benefits according to the card type",
  申请步骤介绍: "Introduction to the Application Steps",
  "您仅需享受最快10分钟申请 立即拥有输你的自己的SmartCard":
    "You only need to enjoy the fastest 10-minute application and immediately have your own SmartCard",
  "申请前务必核对申请条件及基础信息!":
    "Be sure to check the application conditions and basic information before applying!",
  "在线会员申请，注册1分钟":
    "Online member application, registration in 1 minute",
  "完成会员申请后开卡，开卡填写资料最长5分钟":
    "After completing the member application, the longest time to fill in the card opening information is 5 minutes",
  "将USDT转入钱包，增加自己的数字资产":
    "Transfer USDT to the wallet to increase your digital assets",
  "（请根据需求，量力而行）":
    "(Please act according to your needs and capabilities)",
  "申请开卡，选择您希望的品牌卡片（visa":
    "Apply for card opening and select the brand card you want (visa",
  "或master），将USDT转入卡内转变为消":
    "or master), transfer USDT into the card to convert it into consumption",
  "费额度（此操作可重复进行，可逆向操":
    "amount (this operation can be repeated and can be reversed)",
  "作，U转卡2%-3%费用，卡转回U免费）":
    "operation, there is a 2%-3% fee for U to card transfer, and card to U transfer is free)",
  立即开卡: "Open Card Immediately",
  语言: "Language",
  支付产品: "Payment Product",
  线下支付: "Offline Payment",
  线上支付: "Online Payment",
  关于我们: "About Us",
  USDT支持: "USDT Support",
  收费标准: "Charging Standard",
  网上支付: "Online Payment",
  联系我们: "Contact Us",
  常见问题: "Frequently Asked Questions",
  网站使用条款: "Website Terms of Use",
  Cookie声明: "Cookie Statement",
  立即下载: "Download Now",
  跨境线上消费模式: "Cross-border Online Consumption Mode",
  "世界有多大，心就有多大，支持绑定多电子支付场景，GooglePlay、AppStore、Steam、 Amazon. 全宇宙都是你的消费乐园!":
    "The world is as big as your heart. It supports binding to multiple electronic payment scenarios, such as GooglePlay, AppStore, Steam, and Amazon. The whole universe is your consumption paradise!",
  "游艇、私人飞机、豪车预定服务，我们搞定所有，您尽情享受":
    "Yacht, private jet, luxury car reservation service, we take care of everything, and you enjoy it to the fullest",
  "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100%享受首年年费减免服务":
    "First-year annual fee waiver service for card application. Through super preferential policies, if you apply for the card now, you can enjoy 100% first-year annual fee waiver service",
  费率及费用介绍: "Introduction to Rates and Fees",
  用户协议: "User Agreement",
  隐私协议: "Privacy Policy",
  账户删除声明: "Account Deletion Statement",
  激活指南: "Activation Guide",
  首页: "Home",
};
export default en;
