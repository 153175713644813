<template>
  <div>
    <Header />
    <div class="open-card-container">
      <div class="open-title">
        <span> Card Activation Guide</span>
      </div>
      <div class="open-card-content">
        <div>
          <p class="open-card-title">
            <span>Step 1:</span>
          </p>
          <p class="open-card-text">
            <span
              >Open the app, tap the Card List button at the bottom, and go to
              the Card List page</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard1.png" alt="" />
          </div>
        </div>
        <div>
          <p class="open-card-title">
            <span>Step 2:</span>
          </p>
          <p class="open-card-text">
            <span
              >On the Card page, select the physical card to activate, then tap
              the Activate button to go to the activation page</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard2.png" alt="" />
          </div>
        </div>
        <div>
          <p class="open-card-title">
            <span>Step 3:</span>
          </p>
          <p class="open-card-text">
            <span
              >Enter your 16-digit card number and tap "Get Activation Code".
              We'll send the code to your cardholder email. After entering the
              code, tap the "Activate" button at the bottom to complete
              activation</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard3.png" alt="" />
          </div>
        </div>
        <div>
          <p class="open-card-title">
            <span>Step 4:</span>
          </p>
          <p class="open-card-text">
            <span
              >Your card is now activated. Tap "OK" on the pop-up to return to
              the card list page and wait for the activation to complete.</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <FooterSection /> -->
  </div>
</template>

<script>
import Header from "@/components/one/header.vue";
// import FooterSection from "@/components/layout/FooterSection.vue";
export default {
  components: {
    Header,
    // FooterSection,
  },
};
</script>
<!-- 媒体查询 -->
<style scoped lang="scss">
// 电脑端样式
.open-card-container {
  width: 400px;
  margin: 0 auto;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 30px;
}
.open-title {
  color: #000;
  font-family: MiSans;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
}
.open-card-content {
  .open-card-title {
    color: #ff0000;
    font-family: MiSans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .open-card-text {
    color: #000;
    font-family: MiSans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .open-card-content-img {
    margin-bottom: 20px;
  }
}

// 移动端样式
@media (max-width: 768px) {
  .open-title {
    //文字居中
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .open-card-container {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 10px 30px;
  }
  .open-card-container {
    .open-card-title {
      color: red;
      font-family: MiSans;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0%;
      margin-bottom: 2px;
    }
    .open-card-text {
      color: #000;
      font-family: MiSans;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0%;
      margin-bottom: 10px;
    }
    .open-card-content-img {
      margin-bottom: 20px;
    }
  }
}
</style>
